<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo ponto</span>
    </button>
    <b-table
      :data="allBoardingPoints"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="ID"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.id }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="boarding_time"
        label="Horário"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.boarding_time }}</b-table-column
      >

      <b-table-column
        field="city"
        label="Cidade"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.city }}</b-table-column
      >

      <b-table-column
        field="state"
        label="Estado"
        sortable
        :searchable="true"
        v-slot="props"
        >{{ props.row.state }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Horário:</strong>
                <small>{{ props.row.boarding_time }}</small>
                <br />
                <strong>Cidade:</strong>
                <small>{{ props.row.city }}</small>
                <br />
                <strong>Estado:</strong>
                <small>{{ props.row.state }}</small>
                <br />
                <strong>Local:</strong>
                <small>{{ props.row.boarding_place }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Local *">
              <b-input v-model="model.boarding_place" required></b-input>
            </b-field>
            <b-field label="Horário">
              <b-timepicker
                rounded
                placeholder="Selecione o horário"
                icon="clock"
                hour-format="24"
                v-model="model.boarding_time"
                locale="pt-BR"
              >
              </b-timepicker>
            </b-field>
            <b-field label="Estado *">
              <b-select
                @input="statesCities"
                placeholder="Selecione um estado"
                required
                v-model="model.state"
              >
                <option
                  v-for="(item, index) in states"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Cidade *">
              <b-select
                placeholder="Selecione uma cidade"
                required
                v-model="model.city"
              >
                <option
                  v-for="(item, index) in cities"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/boarding-points/store/service'
import BoardingPoint from '../models/boarding-point'
import StatesCities from '../assets/data/estados-cidades.json'

export default {
  name: 'Taxes',
  data() {
    return {
      model: BoardingPoint.model,
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
      cities: [],
      states: [],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('boardingPoints', ['allBoardingPoints']),
  },
  methods: {
    ...mapActions('boardingPoints', ['getAllBoardingPoints']),
    async init() {
      await this.getAllBoardingPoints()
      this.states = StatesCities.states.map((x) => {
        return { id: x.initials, name: x.name }
      })

      this.cities = StatesCities.states[0].cities
      this.model.city = StatesCities.states[0].cities[0]
      this.model.state = this.states[0].id
    },
    statesCities() {
      this.cities = StatesCities.states.find(
        (x) => x.initials === this.model.state
      ).cities
    },
    async save() {
      const boardingPoint = { ...this.model }

      if (boardingPoint.boarding_time) {
        boardingPoint.boarding_time = this.$moment(
          boardingPoint.boarding_time
        ).format('HH:mm')
      }

      try {
        const save = boardingPoint.id
          ? await service.updateBoardingPoint(boardingPoint)
          : await service.saveBoardingPoint(boardingPoint)

        await this.$success('Ponto')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o ponto de embarque?')

        if (result.isConfirmed) {
          await service.deleteBoardingPoint(id)

          await this.$delete('Ponto')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }

      if (this.model.boarding_time) {
        const boardingTime = new Date()
        boardingTime.setHours(this.model.boarding_time.split(':')[0])
        boardingTime.setMinutes(this.model.boarding_time.split(':')[1])
        this.model.boarding_time = boardingTime
      }
    },
  },
}
</script>

<style scoped></style>
