import { render, staticRenderFns } from "./BoardingPoints.vue?vue&type=template&id=04678756&scoped=true"
import script from "./BoardingPoints.vue?vue&type=script&lang=js"
export * from "./BoardingPoints.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04678756",
  null
  
)

export default component.exports